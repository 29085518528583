<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-spacer />
      </v-col>
      <v-col cols="12" xs="10" md="4">
        <v-card class="mx-auto" :loading="loading" :disabled="loading">
          <v-card-title>
            <v-row dense>
              <v-col cols="12" class="text-center">
                <img height="55" :src="require('@/assets/logo_full.png')" />
                <br />
                <h5>Inicio de sesión</h5>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense v-if="!mfa">
              <v-col cols="12">
                <v-form ref="form_data" lazy-validation>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        label="Nombre de usuario*"
                        type="email"
                        v-model="data.email"
                        :rules="rules.text50Required"
                        :disabled="loading"
                        required
                        prepend-icon="mdi-account"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Contraseña*"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="data.password"
                        :disabled="loading"
                        required
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        prepend-icon="mdi-lock"
                      />
                    </v-col>
                    <v-col cols="6">
                      <drag-verify
                        v-if="drag_verify_show"
                        :width="200"
                        :height="30"
                        text="Deslizar a la derecha"
                        success-text="Correcto"
                        background="#cccccc"
                        progress-bar-bg="#FFFF99"
                        completed-bg="#66cc66"
                        handler-bg="#fff"
                        text-size="15px"
                        @passcallback="dragVerify"
                      />
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="info"
                            v-on="on"
                            x-small
                            dark
                            fab
                            @click.prevent="handleSubmit"
                            :loading="loading"
                            :disabled="!data.drag_verify"
                          >
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Iniciar Sesión'" />
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row dense v-else>
              <v-col cols="12">
                <p>
                  Para continuar con esta operación se enviara un codigo de
                  verificación al correo <b v-text="login.email2" />, favor de
                  introducirlo a continuación.
                </p>
              </v-col>
              <v-col cols="12">
                <v-form
                  v-on:submit.prevent
                  ref="form_code_verify"
                  lazy-validation
                >
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        label="Código"
                        v-model="code"
                        type="text"
                        dense
                        counter="8"
                        :rules="rules.required"
                        :disabled="code_loading"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        @click="logout"
                        :disabled="code_loading"
                        :loading="code_loading"
                      >
                        <v-icon left v-text="'mdi-arrow-left'" />
                        Átras
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="success"
                        @click="codeVerify"
                        :disabled="code_loading"
                        :loading="code_loading"
                      >
                        <v-icon left v-text="'mdi-lock'" />
                        Verificar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="8" offset-md="2" class="text-center">
        <!-- Presentamos el nuevo módulo de facturación, se anexa video con
        instrucciones de ingreso y uso.
        <br /> -->
        En caso de dudas comunicarse al 5552498383
      </v-col>
      <!-- <v-col cols="12" sm="12" md="8" offset-md="2">
        <div style="position: relative; padding-bottom: 56.25%; height: 0">
          <iframe
            src="https://www.loom.com/embed/7d0812857e9c4f37bd1562c7ff02ab0a"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          />
        </div>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";
//import functions
import { logIn, logOut } from "../../requests/authRequest";
import { msgAlert, rules, URL_API, headersToken } from "../../control";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import dragVerify from "vue-drag-verify";

export default {
  components: {
    BtnCircle,
    dragVerify,
  },
  data: () => ({
    page_title: "Iniciar Sesión",
    //vars control
    loading: false,
    showPassword: false,
    //data for api
    data: {
      email: "",
      password: "",
      drag_verify: false,
    },
    //rules form data
    rules: rules(),
    mfa: false,
    login: null,
    code_verify: false,
    code: "",
    code_loading: false,
    drag_verify_show: true,
  }),
  methods: {
    authVerify() {
      this.$store.dispatch("logInAction", this.login);
      window.location.assign("/home");
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.loading = true;
        this.drag_verify_show = false;

        logIn(this.data).then((response) => {
          if (response.auth) {
            this.login = response;

            if (this.login.mfa) {
              this.mfa = true;
              this.code_verify = false;
              this.code = "";
              this.code_loading = false;

              Axios.post(
                URL_API + `/verify/codes`,
                {
                  id: null,
                },
                headersToken(this.login.token)
              ).then((response) => {
                if (!response.data.success) {
                  this.$swal.fire(msgAlert("error", response.data.message));
                }

                this.loading = false;
              });
            } else {
              this.authVerify();
            }
          } else {
            console.log(response.message);
            this.$swal.fire(msgAlert("error", response.message));
            this.loading = false;
            this.drag_verify_show = true;
            this.data.drag_verify = false;
          }
        });
      }
    },
    codeVerify() {
      if (this.$refs.form_code_verify.validate()) {
        this.code_loading = true;
        this.code_verify = false;

        Axios.post(
          URL_API + `/verify/codes/use`,
          {
            code: this.code,
          },
          headersToken(this.login.token)
        ).then((response) => {
          //console.log(response.data);
          if (response.data.success) {
            this.authVerify();
          } else {
            this.$swal.fire(msgAlert("error", response.data.message));
          }

          this.code_loading = false;
        });
      }
    },
    logout() {
      this.code_loading = true;

      logOut(this.login.token).then((response) => {
        this.$store.dispatch("logOutAction");

        if (response.success) {
          this.code_loading = false;
          this.mfa = false;
        } else {
          console.log(response.message);

          this.$swal.fire({
            showConfirmButton: false,
            icon: response.success ? "success" : "error",
            text: response.message,
            timer: 2000,
          });
        }
      });
    },
    dragVerify() {
      this.data.drag_verify = true;
    },
  },
};
</script>

<style >
.v-card {
  border-top: 10px solid #212121 !important;
}
</style>